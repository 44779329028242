<template>
  <v-container class="mt-5 ml-2">
    <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">

  		<v-col cols="12">
		  	<v-card class="shadowCard">
		      <v-toolbar
		        flat
		      >
		        <v-toolbar-title>Gráfica de contactos</v-toolbar-title>
		        <v-spacer></v-spacer>
		        <v-btn 
              color="primary" 
              @click="getContactos()" 
              small
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>
		      </v-toolbar>

          <!-- FILTROS -->
		      <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_inicio"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fecha_final"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

		      <v-card-text class="pb-6">

		        <v-row>
		        	<v-col cols="12" v-if="verGrafica">
				        <v-card class="shadowCard">
				          <v-card-title primary-title>
				            Gráfica por escuela y contactos
				          </v-card-title>
                  
                  <!-- GRÁFICA CONTACTOS -->
				          <v-card-text>
				            <vue-apex-charts
                      v-if="verGrafica"
				              type="area" height="350" :options="chartOptions" :series="series"
				            ></vue-apex-charts>
				          </v-card-text>

                  <v-divider></v-divider>

                  <!-- TABLA DE CONTACTOS -->
                  <v-card-text>
                    <v-data-table
                      v-if="verGrafica"
                      :headers="headers2"
                      :items="dataTabla"
                      class=""
                      hide-default-footer
                      fixed-header
                      :items-per-page="dataTabla.length"
                      dense
                      mobile-breakpoint="200"
                    >
                      <template v-slot:top>
                        <v-card-title primary-title>
                          Contactos por vendedora
                        </v-card-title>
                      </template>
                      <template v-slot:no-data>
                        <v-btn
                          color="primary"
                          @click="getContactos()"
                          small
                        >
                          Actualizar
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
				        </v-card>
              </v-col>

              <!-- GRÁFICA DE INSCRITOS -->
              <v-col cols="12" v-if="verGraficaInbi">
                <v-card class="shadowCard">
                  <v-card-title primary-title>
                    Gráfica Inscritos
                  </v-card-title>
                  
                  <!-- GRÁFICA CONTACTOS -->
                  <v-card-text>
                    <vue-apex-charts
                      v-if="verGraficaInbi"
                      type="area" height="350" :options="chartOptionsInbi" :series="seriesContactosInbi"
                    ></vue-apex-charts>
                  </v-card-text>

                  <v-divider></v-divider>
                  
                  <!-- TABLA DE CONTACTOS -->
                  <v-card-text>
                    <v-data-table
                      :headers="headers2"
                      :items="dataTablaInscritos"
                      class=""
                      hide-default-footer
                      fixed-header
                      :items-per-page="dataTablaInscritos.length"
                      dense
                      mobile-breakpoint="200"
                    >
                      <template v-slot:item="{ item, index }">
                          <!-- v-for="(data, i) in dataTablaInscritos" -->
                        <tr
                          :data-category-id="data.vendedora"
                          :data-id="item.vendedora">
                          <td v-for="(h, i) in headers2">
                            <span 
                              v-if="`${h.value}` == 'vendedora'"
                            >
                              {{ item[`${h.value}`] }}
                            </span>

                            <span 
                              v-else-if="typeof item[`${h.value}`] == 'object'"
                            > 
                              <v-chip 
                                @click="abrirDialogAlumnos( item[`${h.value}`] )"
                                color="green"
                                small
                                dark
                                v-if="item[`${h.value}`].length > 0"
                              >
                                {{ item[`${h.value}`].length  }}
                              </v-chip>
                              
                              <span v-else>0</span>
                            </span>
                            
                            <span 
                              v-else
                            >
                              {{ 0 }}
                            </span>

                          </td>
                        </tr>
                      </template>
                      
                      <template v-slot:top>
                        <v-card-title primary-title>
                          Inscritos por vendedora
                        </v-card-title>
                      </template>

                      <template v-slot:no-data>
                        <v-btn
                          color="primary"
                          @click="getContactos()"
                          small
                        >
                          Actualizar
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
		        </v-row>
		      </v-card-text>
			  </v-card>
  		</v-col>


      <v-dialog
        v-model="dialogAlumnos"
        scrollable
        max-width="600px"
      >
        <v-card>
          <v-card-title>Alumnos</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              v-if="verGrafica"
              :headers="headers3"
              :items="alumnos"
              class=""
              hide-default-footer
              fixed-header
              :items-per-page="alumnos.length"
              dense
              mobile-breakpoint="200"
            >
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="getContactos()"
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogAlumnos = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
  	</v-row>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'

  import XLSX from 'xlsx'
  import VueApexCharts from 'vue-apexcharts'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores             from '@/mixins/validarErrores'
  import funcionesExcel             from '@/mixins/funcionesExcel'
  import graficaContactosMarketing  from '@/mixins/graficaContactosMarketing'


  export default {

    mixins: [ validarErrores, funcionesExcel, graficaContactosMarketing ],

    components: {
      VueApexCharts,
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


      fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      fechasUnicas:[],
      data:[],

      headers: [
        { text: 'id_ciclo'  , value: 'id_ciclo' },
        { text: 'ciclo'     , value: 'ciclo' },
        { text: 'Ingreso'   , value: 'sumaingreso' , align:'end'},
        { text: 'Egreso'    , value: 'sumaegreso'  , align:'end'},
      ],

      headers3: [
        { text: 'alumno'        , value: 'alumno' },
        { text: 'matricula'     , value: 'matricula' },
      ],
      
      alumnos:[],
      dialogAlumnos:false,

      dataTabla:[],
			headers2:[],

      verGrafica: false,
      series: [{
        name: 'FAST',
        data: []
      }, {
        name: 'INBI',
        data: []
      }],

      chartOptions: {
        chart: {
          height: 500,
          type: 'area'
        },

        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth'
        },

        xaxis: {
          // type: 'caracter',
          // categories: [],
          categories: [],
          
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
          tickPlacement: 'on',
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },
      },
    }),

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    watch:{
      fecha_inicio( value ){ if(value){ this.getContactos() } },
      fecha_final( value ){ if(value){ this.getContactos() } }
    },

    created( ){
      this.getContactos()
    },

    methods: {
      getContactos () {
      	this.series = null

        this.series = [{
          name: 'FAST',
          data: []
        }, {
          name: 'INBI',
          data: []
        }]

        this.chartOptions.xaxis.categories = []

        this.cargar = true
        this.verGrafica = false

        const payload = {
        	fecha_inicio: this.fecha_inicio, 
        	fecha_final:  this.fecha_final
        }
        // Mandamos a llamar a la api
        this.$http.post('marketing.contactos',payload).then(response=>{
        	this.fechasUnicas           = response.data.getFechasUnicas
        	this.data                   = response.data.graficaContactos
        	this.dataTabla              = response.data.dataTabla
          this.headers2               = response.data.headers2

        	const fechasFAST = this.data.filter( el=> { return el.escuela == 'FAST'})
        	const fechasINBI = this.data.filter( el=> { return el.escuela == 'INBI'})

          for( const i in this.fechasUnicas ){
            const { fecha_creacion } = this.fechasUnicas[i]
            this.chartOptions.xaxis.categories.push(fecha_creacion)

            const existeFechaContactosFast = fechasFAST.find( el => el.fecha_creacion == fecha_creacion )

            if( existeFechaContactosFast ){
              this.series[0].data.push(existeFechaContactosFast.conteo)
            }else{
              this.series[0].data.push(0)
            }

            const existeFechaContactosInbi = fechasINBI.find( el => el.fecha_creacion == fecha_creacion )

            if( existeFechaContactosInbi ){
              this.series[1].data.push(existeFechaContactosInbi.conteo)
            }else{
              this.series[1].data.push(0)
            }

          }

        	// for( const i in fechasFAST ){
        	// 	const { fecha_creacion, conteo } = fechasFAST[i]
          //   this.series[0].data.push(conteo)
        	// }

        	// for( const i in fechasINBI ){
        	// 	const { fecha_creacion, conteo } = fechasINBI[i]
        	// 	this.series[1].data.push(conteo)
        	// }

          this.consultar( )

          this.verGrafica = true

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialogAlumnos( items ){
        this.alumnos = items
        console.log( this.alumnos )
        this.dialogAlumnos = true 
      }

    },
  }
</script>